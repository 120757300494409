import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import cx from "../UserProfile.module.scss";
import lx from "./Coupons.module.scss";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Usermenu } from "../../../../components/Website";
import { Banner1 } from "../../../../assets/images";
import axios from "axios";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceTemplate from "./InvoiceTemplate";
import PrintableInvoice from "./printTemplate"; // Assuming you have created this component as per your requirement

const Coupons = (props: any) => {
  const navigate = useNavigate();
  const hideButton = localStorage.getItem("userToken");
  const [localdata, setlocaldata] = useState(localStorage.getItem("userData"));
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  const [loaderStatus, setloaderStatus] = useState("");
  const [cardlistData, setCardlistData] = useState<any[]>([]);
  const printRef = useRef(null);

  function convertMillisecondsToDate(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return `${formattedDate} ${formattedTime}`;
  }

  useEffect(() => {
    if (hideButton) {
      GetCouponDetails();
    }
  }, []);

  function GetCouponDetails() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_WEBSITE}/getcouponlist?id=${profileData?._id}`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.data.length == 0) {
          setloaderStatus("No Card Available");
        } else {
          setloaderStatus("");
        }

        let CardsData: any = [];
        for (let key of response?.data?.data) {
          if (key.time?.accepted) {
            key.time.accepted = convertMillisecondsToDate(key.time?.accepted);
            CardsData.push(key);
          } else {
            CardsData.push(key);
          }
        }
        setCardlistData(CardsData.reverse());
      })
      .catch(function (error) {});
  }

  const handlePrint = (item) => {
    const printContent = document.getElementById(`printable-content-${item._id}`);
    const printWindow = window.open("", "_blank");
    printWindow?.document.write(`
      <html>
        <head>
          <title>Print Invoice</title>
          <style>
            ${document.getElementsByTagName("style")[0].innerHTML} /* This copies the existing styles */
          </style>
        </head>
        <body>${printContent?.innerHTML}</body>
      </html>
    `);
    printWindow?.document.close();
    printWindow?.print();
  };

  return (
    <>
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bannersection}`}>
          <img src={Banner1} />
          <div className={`${cx.bannerContent}`}>
            <h2>Dashboard</h2>
          </div>
        </div>
      </section>
      <section className={`${cx.complete_profile}`}>
        <Container>
          <form className={`${cx.login_form}`}>
            <Row>
              <Col md={3}>
                <Usermenu />
              </Col>

              <Col md={9}>
                <div className={`${cx.contentBody}`}>
                  <Col md={12} className={`${cx.profileTitle}`}>
                    <h5>Taxi Voucher</h5>
                    
                    <Button
                      onClick={() => {
                        navigate("/booking-ride", {
                          state: {
                            data: {
                              bookingStatus: "purchase",
                            },
                          },
                        });
                      }}
                      className={`btn ${cx.addNew}`}
                    >
                      Create Taxi Voucher
                    </Button>
                  </Col>

                  <div className={`${cx.contentInside}`}>
                    <Row>
                      {cardlistData &&
                        cardlistData.length > 0 &&
                        cardlistData?.map((item: any) => {
                          return (
                            <>
                              {item?.basic?.ride_status === "scheduled" ? (
                                <Col lg={12} key={item._id}>
                                  <div className={`${lx.couponBox}`}>
                                    <div className={`${lx.background}`}>
                                      <Row>
                                        <Col lg={6}>
                                          <ul className={`${lx.location}`}>
                                            <li>{item?.location?.source?.address}</li>
                                            <li>{item?.location?.destination?.address}</li>
                                          </ul>
                                        </Col>
                                        <Col lg={3} className="text-end">
                                          <div className={`${lx.code}`}>{item?.coupon}</div>
                                          <br />
                                        </Col>
                                        <Col lg={3} className="text-end">
                                          <PDFDownloadLink
                                            document={<InvoiceTemplate invoiceData={item} />}
                                            fileName="coupon.pdf"
                                          >
                                            <div className={`${lx.codeDownload}`}>Download</div>
                                          </PDFDownloadLink>
                                          <Button
                                            onClick={() => handlePrint(item)}
                                            className={`${lx.printButton}`}
                                          >
                                            Print Voucher
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                    {/* Hidden printable content */}
                                    <div
                                      id={`printable-content-${item._id}`}
                                      style={{ display: "none" }}
                                    >
                                      <PrintableInvoice invoiceData={item} />
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                <Col lg={12} key={item._id}>
                                  <div className={`${lx.couponBox}`}>
                                    <div className={`${lx.backgroundExpired}`}>
                                      <Row>
                                        <Col lg={6}>
                                          <ul className={`${lx.location}`}>
                                            <li>{item?.location?.source?.address}</li>
                                            <li>{item?.location?.destination?.address}</li>
                                          </ul>
                                        </Col>
                                        <Col lg={3} className="text-end">
                                          <div className={`${lx.code}`}>{item?.coupon}</div>
                                          <br />
                                        </Col>
                                        <Col lg={3} className="text-end">
                                          <PDFDownloadLink
                                            document={<InvoiceTemplate invoiceData={item} />}
                                            fileName="coupon.pdf"
                                          >
                                            <div className={`${lx.codeDownload}`}>Download</div>
                                          </PDFDownloadLink>
                                          <Button
                                            onClick={() => handlePrint(item)}
                                            className={`${lx.printButton}`}
                                          >
                                            Print Voucher
                                          </Button>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={6}>
                                          <h1>EXPIRED</h1>
                                        </Col>
                                        <Col lg={6} className="text-start">
                                          <ul>
                                            <li>
                                              {item.time?.accepted
                                                ? `Used Date : ${item.time?.accepted}`
                                                : `Used Date Not available`}
                                            </li>
                                            <li>
                                              {item.basic?.vehicle
                                                ? `Used by vehicle : ${item.basic?.vehicle.title} `
                                                : `Used by vehicle Not available`}
                                            </li>
                                          </ul>
                                        </Col>
                                      </Row>
                                    </div>
                                    {/* Hidden printable content */}
                                    <div
                                      id={`printable-content-${item._id}`}
                                      style={{ display: "none" }}
                                    >
                                      <PrintableInvoice invoiceData={item} />
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </>
                          );
                        })}
                      {loaderStatus && (
                        <div className="d-flex justify-content-center">{loaderStatus}</div>
                      )}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </section>
    </>
  );
};

export default Coupons;
