import React, { useEffect, useState } from "react";
import cx from "../UserProfile.module.scss";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { AddedCard, Usermenu } from "../../../../components/Website";
import { Banner1 } from "../../../../assets/images";
import { AddNewCard, DeletePopup } from "../../../../components/Website/Modals";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";

const PaymentPage = (props: any) => {
  const [paymentshow, setpaymentShow] = useState(false);
  const handlepaymentClose = () => setpaymentShow(false);
  const handlepaymentShow = () => setpaymentShow(true);
  //-----Get Cards-List-----//
  const [loaderStatus, setloaderStatus] = useState("");
  const [cardlistData, setCardlistData] = useState<any[]>([]);
  const [selectedCardCount, setSelectedCardCount] = useState(0);

  useEffect(() => {
    Getcard();
  }, []);
  function Getcard() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PAYMENTS}/list_card`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(response, "Getcard");
        if (response.data.data.length == 0) {
          setloaderStatus("No Card Available");
        } else {
          setloaderStatus("");
        }
        let CardsData = response.data.data;
        setCardlistData(CardsData);
        setSelectedCardCount((prev: number) => prev + 1);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  const [show, setShow] = useState(false);
  const [selectedCardiId, setSelectedCardId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //----------//-------------//
  const [deleteData, setDeleteData] = useState<any>();
  function CardDelete() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PAYMENTS}/delete_card/${deleteData}`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
      },
    };

    axios(config)
      .then(function (response) {
       // console.log(CardDelete, "CardDelete");
        handleClose();
        Getcard();
        // setDeleteData(response.data.data)
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  //----------set-default-card-----//
  useEffect(() => {
    setSelectedCardId(
      cardlistData?.find((event): any => event?.is_default)?.card_id
    );
  }, [selectedCardCount]);

  function Defaultcard(cardId: any) {
    setSelectedCardId(cardId);
    let data = JSON.stringify({
      card_id: cardId,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PAYMENTS}/set_default_card`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(Defaultcard, "Defaultcard");
        Getcard();
        //handleClose();
      })
      .catch(function (error) {
        Getcard();
       // console.log(error);
      });
  }
  //---------------//--------------//
 
  return (
    <>
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bannersection}`}>
          <img src={Banner1} />
          <div className={`${cx.bannerContent}`}>
            <h2>Dashboard</h2>
          </div>
        </div>
      </section>
      <section className={`${cx.complete_profile}`}>
        <Container>
          <Row>
            <Col md={3}>
              <Usermenu />
            </Col>

            <Col md={9}>
              <div className={`${cx.contentBody}`}>
                <Col md={12} className={`${cx.profileTitle}`}>
                  <h5>Payment</h5>
                  <button
                    type="button"
                    className={`btn ${cx.addNew}`}
                    onClick={handlepaymentShow}
                  >
                    Add New Card
                  </button>
                </Col>

                <div className={`${cx.contentInside}`}>
                  <Row>
                    <Col lg={6}>
                      {cardlistData &&
                        cardlistData.length > 0 &&
                        cardlistData?.map((item: any) => {
                          return (
                            <>
                              <div className="position-relative">
                                <button
                                  className={`${cx.addedCard} ${cx.radio} ${
                                    item.is_default == true
                                      ? selectedCardiId && cx.active
                                      : ""
                                  }`}
                                >
                                  <ul>
                                    <h6>{item.name}</h6>
                                    <li>Card Number : {item.number}</li>
                                    <li>Brand : {item.brand}</li>
                                  </ul>

                                  <div className={`${cx.signupPoint}`}>
                                    <p>
                                      <Button
                                        type="button"
                                        className={`${cx.resendBtnBottom} `}
                                        onClick={() => {
                                          Defaultcard(item.card_id);
                                        }}
                                      >
                                        {item.card_id !== selectedCardiId
                                          ? "Set Default Card"
                                          : "Default Card"}
                                      </Button>
                                    </p>
                                  </div>
                                </button>
                                <div className={`${cx.delet_card}`}>
                                  <AiFillDelete
                                    className={`${cx.delet_icon}`}
                                    onClick={() => {
                                      handleShow();
                                      setDeleteData(item?.card_id);
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      {loaderStatus && (
                        <div className="d-flex justify-content-center">
                          {loaderStatus}
                        </div>
                      )}
                      <DeletePopup
                        show={show}
                        handleClose={handleClose}
                        CardDelete={CardDelete}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <AddNewCard
        paymentshow={paymentshow}
        handlepaymentClose={handlepaymentClose}
        Getcard={Getcard}
      />
    </>
  );
};

export default PaymentPage;
