import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Col, Modal, Row } from "react-bootstrap";
import Success from "../Success/Success";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./CheckoutFrom.scss";
import { MdDangerous } from "react-icons/md";
import axios from "axios";
import GlobalContext from "../../../store/global-context";
const stripePromise = loadStripe(
  "pk_test_51I8gZdDwBkJNhk4UhgHDocfH5g0GS6Ckjzq82vePr4QHX3BqQn1jmQezANiuLgjuoKGuuaCgwJP9VnqhCIwLMlLZ00v2nk6iRP"
);

const CheckoutForm = ({
  DeductAmount,
  GetCoupon,
  dataCoupon,
  userData,
}: any) => {
  const globalGtx = useContext(GlobalContext);
  console.log(globalGtx.stripeData, "function");
  const [cardData, setCardData] = useState<any>();
  useEffect(() => {
    setCardData(globalGtx.stripeData);
  }, []);
  //---------GET paymentTypeApi ----------//
  const hideButton = localStorage.getItem("userToken");
  const [localdata, setlocaldata] = useState(localStorage.getItem("userData"));
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);

  const [cardlistData, setCardlistData] = useState<number>();
  useEffect(() => {
    if (hideButton) {
      GetpaymentType();
    }
  }, []);
  function GetpaymentType() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_WEBSITE}/settingsdata`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "GetpaymentType");
        let CardsData = response.data.data;
        let earnratiodata =
          CardsData.payment.notrans_debit_earn_ratio[0] +
          (DeductAmount / 100) * CardsData.payment.credit_earn_ratio[0] +
          CardsData.payment.driver_earn_ratio[0];
        let totalcharges = DeductAmount - earnratiodata;
        setCardlistData(totalcharges);
      })
      .catch(function (error) {});
  }
  //------stripe pop-up successful-----//
  const [stripeShow, setStripeShow] = useState(false);
  //--------------/stripe pop-up failed/--------//
  const [couponShow, setCouponShow] = useState(false);
  const CouponhandleClose = () => setCouponShow(false);
  const CouponhandleShow = () => setCouponShow(true);
  //------------------------//
  const stripe = useStripe()!;
  const elements = useElements()!;
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }
    // const merchant :any = stripe_id("acct_1IFBzvRbE08E1m8K")
    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await fetch(
      `${process.env.REACT_APP_API_PROVIDER}/create_payment_intent`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount: DeductAmount,
          currency: "CAD",
          paymentType: "Credit Card",
          payment_method_types:"card_present",
          stripe_id: "acct_1IFBzvRbE08E1m8K",
          bookedby: "direct",
          automatic_payment_methods: true,
          application_fee_amount: cardlistData,
          customer: userData?.stripe_id,
          // transfer_data: {
          //     destination: merchant,
          // },
        }),
      }
    );
    console.log(res, "res");
    const { client_secret: clientSecret } = await res.json();
    console.log(clientSecret, elements, "datadtatatdta");

    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)!,
        billing_details: {},
      },
    });

    if (error) {
      CouponhandleShow();
      setStripeShow(false);
      console.log("Payment failed:", error);
    } else {
      setStripeShow(true);
      GetCoupon();
      console.log("Payment successful!");
    }
  };

  return (
    <>
      {stripeShow ? (
        <Row>
          <Col lg={16}>
            <Success />
          </Col>
          <Col lg={16}>
            Your Coupon Generate Successfully:{" "}
            <span
              className="couponCode"
              style={{ fontWeight: "600", textTransform: "uppercase" }}
            >
              {dataCoupon?.coupon}
            </span>
          </Col>
        </Row>
      ) : (
        <form onSubmit={handleSubmit}>
          {/* <PaymentElement /> */}
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="cc-number">Card Number</label>
              <CardNumberElement
                id="cc-number"
                className="form-control"
                //  defaultValue={cardData.number}
                // options={CARD_ELEMENT_OPTIONS}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="expiry">Expiration Date</label>
              <CardExpiryElement
                id="expiry"
                className="form-control"
                // options={CARD_ELEMENT_OPTIONS}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="cvc">CVC</label>
              <CardCvcElement
                id="cvc"
                className="form-control"
                // options={CARD_ELEMENT_OPTIONS}
              />
            </div>
          </div>
          <button type="submit" className="paymentBtn" disabled={!stripe}>
            Pay
          </button>
          {errorMessage && <div>{errorMessage}</div>}
        </form>
      )}
      <Modal centered scrollable show={couponShow} onHide={CouponhandleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} className={`cancelledPopup`}>
              <MdDangerous className="closeBtn" />
              <h5>Something went wrong on Stripe's !</h5>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

const StripCmponent = ({
  DeductAmount,
  StripehandleClose,
  GetCoupon,
  dataCoupon,
}: any) => {
  const options: StripeElementsOptions = {
    mode: "payment",
    amount: 1099,
    currency: "cad",
    appearance: { theme: "stripe" },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        DeductAmount={DeductAmount}
        StripehandleClose={StripehandleClose}
        GetCoupon={GetCoupon}
        dataCoupon={dataCoupon}
      />
    </Elements>
  );
};
export default StripCmponent;
