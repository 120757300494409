import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./TermsConditions.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";

import { Container, Form, Col, Row } from "react-bootstrap";
import { Banner1 } from "../../../assets/images";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Checkbox } from "../../../components/Website/Forms";


const TermsConditions = (props: any) => {
  const [show, setShow] = useState(true);
  const [show2, set2Show] = useState(true);

  return (
    <>
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bannersection}`}>
          <img src={Banner1} />
          <div className={`${cx.bannerContent}`}>
            <h2>Terms & Conditions</h2>
          </div>
        </div>
      </section>

      <section className={`${st.sectionPadding}`}>
        <Container>
          <Row>
            <Col lg={12} className={`m-auto`}>
              <div className={`${cx.loginForm}`}>
                <div className={`${cx.contentBody}`}>
                  <h3>Terms Of Service</h3>

                  <h4>1. TERMS AND CONDITION OF USE</h4>
                  <p>By downloading, browsing, accessing or using this Taximo App Taximo and/or Taximo Driver’s App,
                    you agree to be bound by
                    these Terms and Conditions of Use. We reserve the right to amend these terms and conditions at
                    any time. If you disagree
                    with any of these Terms and Conditions of Use, you must immediately discontinue your access to
                    Taximo App and your use
                    of the services offered on Taximo App. Continued use of Taximo App will constitute acceptance of
                    these Terms and
                    Conditions of Use, as may be amended from time to time.
                  </p>

                  <h4>2. DEFINITIONS</h4>
                  <p>In these Terms and Conditions of Use, the following capitalized terms shall have the following
                    meanings, except where
                    the context otherwise requires:
                  </p>
                  <p><b>"Account"</b> means an account created by a User on Taximo App as part of Registration.
                  </p>
                  <p><b>"Merchant"</b> refers to any entity whose products or Samples can be purchased and/or redeemed
                    (as the case may be) via
                    Taximo App.</p>
                  <p><b>"Privacy Policy"</b> means the privacy policy set out in Clause 14 of these Terms and
                    Conditions of
                    Use.</p>
                  <p><b>"Redeem"</b> means to redeem a Merchant’s products or Samples on these Terms and Conditions of
                    Use and</p>
                  <p><b>"Redemption"</b>means the act of redeeming such products or Samples.</p>
                  <p><b>"Register"</b> means to create an Account on Taximo App and "Registration" means the act of
                    creating such an Account.</p>
                  <p><b>"Samples"</b> means samples of Merchant’s products and/or Merchant’s services, including
                    without limitation, any vouchers
                    for the redemption of such services, and "Sample" means any one of them.</p>
                  <p><b>"Services"</b> means all the services provided by Taximo Inc. via Taximo App to Users, and
                    "Service" means any one of them,</p>
                  <p><b>"Users"</b> means users of Taximo App, including you and "User" means any one of them.</p>

                  <h4>3. GENERAL ISSUES ABOUT TAXIMO APP AND THE SERVICES</h4>
                  <p>3.1 Applicability of terms and conditions: The use of any Services and/or Taximo App and the
                    making of any Redemptions
                    are subject to these Terms and Conditions of Use.
                  </p>
                  <p>3.2 Location: Taximo App, the Services and any Redemptions are intended solely for use by Users
                    who access Taximo App in
                    Canada. We make no representation that the Services (or any goods or services) are available or
                    otherwise suitable for
                    use outside of Canada. Notwithstanding the above, if you access Taximo App, use the Services or
                    make any Redemptions
                    from locations outside Canada, you do so on your own initiative and are responsible for the
                    consequences and for
                    compliance with all applicable laws.</p>
                  <p>3.3 Scope: Taximo App, the Services and any Redemptions are for your non-commercial, personal use
                    only and must not be
                    used for business purposes.</p>
                  <p>3.4 Prevention on use: We reserve the right to prevent you using Taximo App and the Service (or
                    any part of them) and to
                    prevent you from making any Redemptions.</p>
                  <p>3.5 Equipment and Networks: The provision of the Services and Taximo App does not include the
                    provision of a mobile
                    telephone or handheld device or other necessary equipment to access Taximo App or the Services
                    or make any Redemptions.
                    To use Taximo App or Services or to make Redemptions, you will require Internet connectivity and
                    appropriate
                    telecommunication links. You acknowledge that the terms of agreement with your respective mobile
                    network provider
                    ("Mobile Provider") will continue to apply when using Taximo App. As a result, you may be
                    charged by the Mobile Provider
                    for access to network connection services for the duration of the connection while accessing
                    Taximo App or any such
                    third-party charges as may arise. You accept responsibility for any such charges that arise.</p>
                  <p>3.6 Permission to use Taximo App: If you are not the bill payer for the mobile telephone or
                    handheld device being used
                    to access Taximo App, you will be assumed to have received permission from the bill payer for
                    using Taximo App.</p>
                  <p>3.7 License to Use Material: By submitting any text or images (including photographs)
                    (“Material”) via the Application,
                    you represent that you are the owner of the Material or have proper authorization from the owner
                    of the Material to use,
                    reproduce and distribute it. You hereby grant us a worldwide, royalty-free, non-exclusive
                    license to use the Material to
                    promote any products or services.</p>

                  <h4>4. REDEMPTIONS</h4>
                  <p>4.1 Need for registration: You must Register to make a Redemption from Taximo App.</p>
                  <p>4.2 Application of these Terms and Conditions of Use: By making any Redemption, you acknowledge
                    that the Redemption is
                    subject to these Terms and Conditions of Use.</p>
                  <p>4.3 Redemption: Any attempted Redemption not consistent with these Terms and Conditions of Use
                    may be disallowed or
                    rendered void at our or the relevant Merchant’s discretion.</p>
                  <p>4.4 Responsibility for Redemptions of perishable products of samples: Each Merchant shall be
                    responsible to ensure that
                    any of its products or Samples for Redemption that are perishable has not expired.</p>
                  <p>4.5 Restrictions: (a) Reproduction, sale, resale or trading of any products or Samples or
                    Redeemed products is
                    prohibited. (b) If any product or Sample is Redeemed for less than its face value, there is no
                    entitlement to a credit,
                    cash or Sample equal to the difference between the face value and the amount Redeemed. (c)
                    Redemption of products or
                    Samples is subject to availability of the relevant Merchants’ stocks.</p>
                  <p>4.6 Sing Post Not Liable: For the avoidance of doubt, Taximo Inc. shall not be liable for any
                    losses or damages suffered
                    by you resulting from a failure by the relevant Merchant to fulfil any Redemptions in accordance
                    with Clause 4.4 or for
                    a failure by us to deliver any products or samples to you due to the unavailability of such
                    products or Samples pursuant
                    to Clause 4.5(c).</p>
                  <p>4.7 Lost/stolen Samples: Neither we nor any Merchant shall be responsible for lost or stolen
                    Samples or products that
                    have been Redeemed.</p>

                  <h4>5. LOCATION ALERTS AND NOTIFICATIONS</h4>
                  <p>5.1 You agree to receive pre-programmed notifications (“Location Alerts”) on Taximo App from
                    Merchants if you have
                    turned on locational services on your mobile telephone or other handheld devices (as the case
                    may be).</p>

                  <h4>6. YOUR OBLIGATIONS</h4>
                  <p>6.1 Merchant terms: You agree to (and shall) abide by the terms and conditions of the relevant
                    Merchant for which your
                    Redemption relates to, as may be amended from time to time.</p>
                  <p>6.2 Accurate information: You warrant that all information provided on Registration and contained
                    as part of your
                    Account is true, complete and accurate and that you will promptly inform us of any changes to
                    such information by
                    updating the information in your Account.</p>
                  <p>6.3 Content on Taximo App and Service: It is your responsibility to ensure that any products,
                    Samples or information
                    available through Taximo App or the Services meet your specific requirements before making any
                    Redemption.</p>
                  <p>6.4 Prohibitions in relation to usage of Services or Taximo App: Without limitation, you
                    undertake not to use or permit
                    anyone else to use the Services or Taximo App: -</p>
                  <p>6.4.1 to send or receive any material which is not civil or tasteful</p>
                  <p>6.4.2 to send or receive any material which is threatening, grossly offensive, of an indecent,
                    obscene or menacing
                    character, blasphemous or defamatory of any person, in contempt of court or in breach of
                    confidence, copyright, rights
                    of personality, publicity or privacy or any other third-party rights;</p>
                  <p>6.4.3 to send or receive any material for which you have not obtained all necessary licenses
                    and/or approvals (from us
                    or third parties); or which constitutes or encourages conduct that would be considered a
                    criminal offence, give rise to
                    civil liability, or otherwise be contrary to the law of or infringe the rights of any third
                    party in any country in the
                    world;</p>
                  <p>6.4.4 to send or receive any material which is technically harmful (including computer viruses,
                    logic bombs, Trojan
                    horses, worms, harmful components, corrupted data or other malicious software or harmful data);
                  </p>
                  <p>6.4.5 to cause annoyance, inconvenience or needless anxiety;</p>
                  <p>6.4.6 to intercept or attempt to intercept any communications transmitted by way of a
                    telecommunications system;</p>
                  <p>6.4.7 for a purpose other than which we have designed them or intended them to be used;</p>
                  <p>6.4.8 for any fraudulent purpose;</p>
                  <p>6.4.9 other than in conformance with accepted Internet practices and practices of any connected
                    networks;</p>
                  <p>6.4.10 in any way which is calculated to incite hatred against any ethnic, religious or any other
                    minority or is
                    otherwise calculated to adversely affect any individual, group or entity; or</p>
                  <p>6.4.11 in such a way as to, or commit any act that would or does, impose an unreasonable or
                    disproportionately large
                    load on our infrastructure.</p>
                  <p>6.5 Prohibitions in relation to usage of Services, Taximo App: Without limitation, you further
                    undertake not to or
                    permit anyone else to: -</p>
                  <p>6.5.1 resell any products or Samples;</p>
                  <p>6.5.2 furnish false data including false names, addresses and contact details and fraudulently
                    use credit/debit card
                    numbers;</p>
                  <p>6.5.3 attempt to circumvent our security or network including to access data not intended for
                    you, log into a server or
                    account you are not expressly authorized to access, or probe the security of other networks
                    (such as running a port
                    scan);</p>
                  <p>6.5.4 execute any form of network monitoring which will intercept data not intended for you;</p>
                  <p>6.5.5 enter into fraudulent interactions or transactions with us or a Merchant (including
                    interacting or transacting
                    purportedly on behalf of a third party where you have no authority to bind that third party or
                    you are pretending to be
                    a third party);</p>
                  <p>6.5.6 extract data from or hack into Taximo App;</p>
                  <p>6.5.7 use the Services or Taximo App in breach of these Terms and Conditions of Use;</p>
                  <p>6.5.8 engage in any unlawful activity in connection with the use of Taximo App or the Services;
                    or</p>
                  <p>6.5.9 engage in any conduct which, in our exclusive reasonable opinion, restricts or inhibits any
                    other customer from
                    properly using or enjoying Taximo App or Services.</p>

                  <h4>7. RULES ABOUT USE OF THE SERVICE AND TAXIMO APP</h4>
                  <p>7.1 We will use reasonable endeavors to correct any errors or omissions as soon as practicable
                    after being notified of
                    them. However, we do not guarantee that the Services or Taximo App will be free of faults, and
                    we do not accept
                    liability for any such faults, errors or omissions. In the event of any such error, fault or
                    omission, you should report
                    it by contacting us at <a href="">www.taximo.ca</a> or 514-829-4669.</p>
                  <p>7.2 We do not warrant that your use of the Services or Taximo App will be uninterrupted and we do
                    not warrant that any
                    information (or messages) transmitted via the Services or Taximo App will be transmitted
                    accurately, reliably, in a
                    timely manner or at all. Notwithstanding that we will try to allow uninterrupted access to the
                    Services and Taximo App,
                    access to the Services and Taximo App may be suspended, restricted or terminated at any time.
                  </p>
                  <p>7.3 We do not give any warranty that the Services and Taximo App are free from viruses or
                    anything else which may have a
                    harmful effect on any technology.</p>
                  <p>7.4 We reserve the right to change, modify, substitute, suspend or remove without notice any
                    information or Services on
                    Taximo App from time to time. Your access to Taximo App and/or the Services may also be
                    occasionally restricted to allow
                    for repairs, maintenance or the introduction of new facilities or services. We will attempt to
                    restore such access as
                    soon as we reasonably can. For the avoidance of doubt, we reserve the right to withdraw any
                    information or Services from
                    Taximo App at any time.</p>
                  <p>7.5 We reserve the right to block access to and/or to edit or remove any material which in our
                    reasonable opinion may
                    give rise to a breach of these Terms and Conditions of Use.</p>

                  <h4>8. SUSPENSION AND TERMINATION</h4>
                  <p>8.1 If you use (or anyone other than you, with your permission uses) Taximo App, any Services in
                    contravention of these
                    Terms and Conditions of Use, we may suspend your use of the Services and/or Taximo App.</p>
                  <p>8.2 If we suspend the Services or Taximo App, we may refuse to restore the Services or Taximo App
                    for your use until we
                    receive an assurance from you, in a form we deem acceptable, that there will be no further
                    breach of the provisions of
                    these Terms and Conditions of Use.</p>
                  <p>8.3 Taximo Inc. shall fully co-operate with any law enforcement authorities or court order
                    requesting or directing
                    Taximo Inc. to disclose the identity or locate anyone in breach of these Terms and Conditions of
                    Use.</p>
                  <p>8.4 Without limitation to anything else in this Clause 8, we shall be entitled immediately or at
                    any time (in whole or
                    in part) to: (a) suspend the Services and/or Taximo App; (b) suspend your use of the Services
                    and/or Taximo App; and/or
                    (c) suspend the use of the Services and/or Taximo App for persons we believe to be connected (in
                    whatever manner) to
                    you, if:</p>
                  <p>8.4.1 you commit any breach of these Terms and Conditions of Use;</p>
                  <p>8.4.2 we suspect, on reasonable grounds, that you have, might or will commit a breach of these
                    Terms and Conditions of
                    Use; or</p>
                  <p>8.4.3 we suspect, on reasonable grounds, that you may have committed or be committing any fraud
                    against us or any
                    person.</p>
                  <p>8.5 Our rights under this Clause 8 shall not prejudice any other right or remedy we may have in
                    respect of any breach or
                    any rights, obligations or liabilities accrued prior to termination.</p>

                  <h4>9. DISCLAIMER AND EXCLUSION OF LIABILITY</h4>
                  <p>9.1 Taximo App, the Services, the information on Taximo App and use of all related facilities are
                    provided on an "as is,
                    as available" basis without any warranties whether express or implied.</p>
                  <p>9.2 To the fullest extent permitted by applicable law, we disclaim all representations and
                    warranties relating to Taximo
                    App and its contents, including in relation to any inaccuracies or omissions in Taximo App,
                    warranties of
                    merchantability, quality, fitness for a particular purpose, accuracy, availability,
                    non-infringement or implied
                    warranties from course of dealing or usage of trade.</p>
                  <p>9.3 We do not warrant that Taximo App will always be accessible, uninterrupted, timely, secure,
                    error free or free from
                    computer virus or other invasive or damaging code or that Taximo App will not be affected by any
                    acts of God or other
                    force majeure events, including inability to obtain or shortage of necessary materials,
                    equipment facilities, power or
                    telecommunications, lack of telecommunications equipment or facilities and failure of
                    information technology or
                    telecommunications equipment or facilities.</p>
                  <p>9.4 While we may use reasonable efforts to include accurate and up-to-date information on Taximo
                    App, we make no
                    warranties or representations as to its accuracy, timeliness or completeness.</p>
                  <p>9.5 We shall not be liable for any acts or omissions of any third parties howsoever caused, and
                    for any direct,
                    indirect, incidental, special, consequential or punitive damages, howsoever caused, resulting
                    from or in connection with
                    Taximo App and the services offered in Taximo App, your access to, use of or inability to use
                    Taximo App or the services
                    offered in Taximo App, reliance on or downloading from Taximo App and/or services, or any
                    delays, inaccuracies in the
                    information or in its transmission including but not limited to damages for loss of business or
                    profits, use, data or
                    other intangible, even if we have been advised of the possibility of such damages.</p>
                  <p>9.6 We shall not be liable in contract, tort (including negligence or breach of statutory duty)
                    or otherwise howsoever
                    and whatever the cause thereof, for any indirect, consequential, collateral, special or
                    incidental loss or damage
                    suffered or incurred by you in connection with Taximo App and these Terms and Conditions of Use.
                    For the purposes of
                    these Terms and Conditions of Use, indirect or consequential loss or damage includes, without
                    limitation, loss of
                    revenue, profits, anticipated savings or business, loss of data or goodwill, loss of use or
                    value of any equipment
                    including software, claims of third parties, and all associated and incidental costs and
                    expenses.</p>
                  <p>9.7 The above exclusions and limitations apply only to the extent permitted by law. None of your
                    statutory rights as a
                    consumer that cannot be excluded or limited are affected.</p>
                  <p>9.8 Notwithstanding our efforts to ensure that our system is secure, you acknowledge that all
                    electronic data transfers
                    are potentially susceptible to interception by others. We cannot, and do not, warrant that data
                    transfers pursuant to
                    Taximo App, or electronic mail transmitted to and from us, will not be monitored or read by
                    others.</p>

                  <h4>10. INDEMNITY</h4>
                  <p>You agree to indemnify and keep us indemnified against any claim, action, suit or proceeding
                    brought or threatened to be
                    brought against us which is caused by or arising out of (a) your use of the Services, (b) any
                    other party’s use of the
                    Services using your user ID, verification PIN and/or any identifier number allocated by Taximo
                    Inc., and/or (c) your
                    breach of any of these Terms and Conditions of Use, and to pay us damages, costs and interest in
                    connection with such
                    claim, action, suit or proceeding.</p>

                  <h4>11. INTELLECTUAL PROPERTY RIGHTS</h4>
                  <p>11.1 All editorial content, information, photographs, illustrations, artwork and other graphic
                    materials, and names,
                    logos and trade marks on Taximo App are protected by copyright laws and/or other laws and/or
                    international treaties, and
                    belong to us and/or our suppliers, as the case may be. These works, logos, graphics, sounds or
                    images may not be copied,
                    reproduced, retransmitted, distributed, disseminated, sold, published, broadcasted or circulated
                    whether in whole or in
                    part, unless expressly permitted by us and/or our suppliers, as the case may be.</p>
                  <p>11.2 Nothing contained on Taximo App should be construed as granting by implication, estoppel, or
                    otherwise, any license
                    or right to use any trademark displayed on Taximo App without our written permission. Misuse of
                    any trademarks or any
                    other content displayed on Taximo App is prohibited.</p>
                  <p>11.3 We will not hesitate to take legal action against any unauthorized usage of our trade marks,
                    name or symbols to
                    preserve and protect its rights in the matter. All rights not expressly granted herein are
                    reserved. Other product and
                    company names mentioned herein may also be the trademarks of their respective owners.</p>

                  <h4>12. AMENDMENTS</h4>
                  <p>12.1 We may periodically make changes to the contents of Taximo App, including to the
                    descriptions and prices of goods
                    and services advertised, at any time and without notice. We assume no liability or
                    responsibility for any errors or
                    omissions in the content of Taximo App.</p>
                  <p>12.2 We reserve the right to amend these Terms and Conditions of Use from time to time without
                    notice. The revised Terms
                    and Conditions of Use will be posted on Taximo App and shall take effect from the date of such
                    posting. You are advised
                    to review these terms and conditions periodically as they are binding upon you.</p>

                  <h4>13. APPLICABLE LAW AND JURISDICTION</h4>
                  <p>13.1 Taximo App can be accessed from all countries around the world where the local technology
                    permits. As each of these
                    places have differing laws, by accessing Taximo App both you and we agree that the laws
                    ofCanada, without regard to the
                    conflicts of laws principles thereof, will apply to all matters relating to the use of Taximo
                    App.</p>
                  <p>13.2 You accept and agree that both you and we shall submit to the exclusive jurisdiction of the
                    courts of Canada in
                    respect of any dispute arising out of and/or in connection with these Terms and Conditions of
                    Use.</p>

                  <h4>14. Privacy Policy</h4>
                  <p>14.1 Access to Taximo App and use of the Services offered on Taximo App by Taximo Inc. and/or its
                    group of companies is
                    subject to this Privacy Policy. By accessing Taximo App and by continuing to use the Services
                    offered, you are deemed to
                    have accepted this Privacy Policy, and in particular, you are deemed to have consented to our
                    use and disclosure of your
                    personal information in the manner prescribed in this Privacy Policy and for the purposes set
                    out in Clauses 3.7 and/or
                    4.1. We reserve the right to amend this Privacy Policy from time to time. If you disagree with
                    any part of this Privacy
                    Policy, you must immediately discontinue your access to Taximo App and your use of the Services.
                  </p>
                  <p>14.2 As part of the normal operation of our Services, we collect, use and, in some cases,
                    disclose information about you
                    to third parties. Accordingly, we have developed this Privacy Policy in order for you to
                    understand how we collect, use,
                    communicate and disclose and make use of your personal information when you use the Services on
                    Taximo App: -

                    <ul>
                      <li>
                        (a) Before or at the time of collecting personal information, we will identify the purposes
                        for which information is
                        being collected.
                      </li>
                      <li>(b) We will collect and use of personal information solely with the objective of fulfilling
                        those purposes specified by
                        us and for other compatible purposes, unless we obtain the consent of the individual
                        concerned or as required by law.</li>
                      <li>(c) We will only retain personal information as long as necessary for the fulfillment of
                        those purposes.</li>
                      <li>(d) We will collect personal information by lawful and fair means and, where appropriate,
                        with the knowledge or consent
                        of the individual concerned.</li>
                      <li>(e) Personal information should be relevant to the purposes for which it is to be used, and,
                        to the extent necessary for
                        those purposes, should be accurate, complete, and up-to-date.</li>
                      <li>(f) We will protect personal information by reasonable security safeguards against loss or
                        theft, as well as
                        unauthorized access, disclosure, copying, use or modification.</li>

                    </ul>
                  </p>
                  <p>We are committed to conducting our business in accordance with these principles in order to
                    ensure that the
                    confidentiality of personal information is protected and maintained.</p>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TermsConditions;
