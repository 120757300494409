import React, { useEffect, useState, Fragment } from "react";
import cx from "./AddedCard.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";
import { DeletePopup } from "../Modals";
import { MdDeleteOutline } from "react-icons/md";
import axios from "axios";
import { Button } from "react-bootstrap";

interface AddCardOptions {
  deleteIcon?: boolean;
  radioButton?: boolean;
  cardlistData?: any[];
  loaderStatus?: string; 
  handlepaymentClose?: () => {};
  Getcard?: () => {};
}

const AddedCard = (props: AddCardOptions) => {
  const [show, setShow] = useState(false);
  const [selectedCardiId, setSelectedCardId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [cardNumber, setCardNumber] = useState("");
  //console.log(cardNumber, "cardNumber");
  let {
    deleteIcon,
    radioButton,
    cardlistData,
    Getcard,
    loaderStatus,
    handlepaymentClose,
  } = props;
  //----------//-------------//
  const [deleteData, setDeleteData] = useState<any>();
  function CardDelete() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PAYMENTS}/delete_card/${deleteData}`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
      },
    };

    axios(config)
      .then(function (response) {
       // console.log(CardDelete, "CardDelete");
        handleClose();
        Getcard && Getcard();
        // setDeleteData(response.data.data)
      })
      .catch(function (error) {
       // console.log(error);
      });
  }
  //----------set-default-card-----//
  useEffect(() => {
    setSelectedCardId(
      cardlistData?.find((event): any => event?.is_default)?.card_id
    );
  }, []);
  function Defaultcard(cardId: any) {
    setSelectedCardId(cardId);
    let data = JSON.stringify({
      card_id: cardId,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PAYMENTS}/set_default_card`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
       // console.log(Defaultcard, "Defaultcard");
        Getcard && Getcard();
        //handleClose();
      })
      .catch(function (error) {
        Getcard && Getcard();
       // console.log(error);
      });
  }
  //---------------//--------------//
  return (
    <>
      {cardlistData &&
        cardlistData.length > 0 &&
        cardlistData?.map((item: any) => {
          return (
            <>
              <div className="position-relative">
                <button
                  className={`${cx.addedCard} ${cx.radio} ${
                    item.is_default == true ? selectedCardiId && cx.active : ""
                  }`}
                  onClick={() => {
                    handlepaymentClose && handlepaymentClose();
                  }}
                >
                  <ul>
                    <h6>{item.name}</h6>
                    <li>Card Number : {item.number}</li>
                    <li>Brand : {item.brand}</li>
                  </ul>

                  <div className={`${cx.signupPoint}`}>
                    <p>
                      <Button
                        className={`${cx.resendBtnBottom} `}
                        onClick={() => {
                          Defaultcard(item.card_id);
                        }}
                      >
                        {item.card_id !== selectedCardiId
                          ? "Set Default Card"
                          : "Default Card"}
                      </Button>
                    </p>
                  </div>
                </button>
                <div className={`${cx.delet_card}`}>
                  <AiFillDelete
                    className={`${cx.delet_icon}`}
                    onClick={() => {
                      handleShow();
                      setDeleteData(item?.card_id);
                    }}
                  />
                </div>
              </div>
            </>
          );
        })}
      {loaderStatus && (
        <div className="d-flex justify-content-center">{loaderStatus}</div>
      )}
      <DeletePopup
        show={show}
        handleClose={handleClose}
        CardDelete={CardDelete}
      />
    </>
  );
};

export default AddedCard;
