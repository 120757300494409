import React, { useState } from "react";
import cx from "./DriverTaxi.module.scss";
import { NavLink } from "react-router-dom";
import { Logo, profile, taxi1 } from "../../../assets/images";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { Rating } from 'react-simple-star-rating'

const DriverTaxi = (props: any) => {
  let {rideAccepted } = props;

  return (
    <>
      <Container>
        <Row>
          <Col lg={12} className="m-0 p-0">
            <button className={`${cx.selectTaxi} `}>
              <img src={rideAccepted?.driver_image} />
              <div className={`${cx.taxiTypeBody}`}>
                <h5>{rideAccepted?.driver_name}</h5>
                <p>
                  <ul>
                  <Rating  initialValue={rideAccepted?.avg_rating} allowHover={false} />
                  </ul>
                </p>
              </div>
            </button>
          </Col>

          <Col lg={12} className="m-0 p-0">
            <button className={`${cx.selectTaxi} `}>
              <img src={rideAccepted?.category_image} />
              <div className={`${cx.taxiTypeBody}`}>
                <h5>{rideAccepted?.car_title}</h5>
                <p>{rideAccepted?.plateno}</p>
              </div>
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DriverTaxi;
